import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import Api from "../services/api"
import moment from "moment"
import 'moment/locale/id'
import { useAuthContext } from "../context/AuthContext"

moment.locale('id')

const ReportTransaction = () => {
    const { token } = useAuthContext()
    const [periode, setPeriode] = useState('Bulan ini')
    const [totalIncome, setTotalIncome] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)
    const [transactionList, setTransactionList] = useState([])
    const [transactionLoader, setTransactionLoader] = useState(false)

    const getReport = async () => {
        try {
            setTransactionLoader(true)
            const payloads = {
                periode: periode
            }
            const response = await Api.post(`transactions/byPeriode`, payloads, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            console.log(response.data)
            setTotalExpense(response.data.data.total_expense)
            setTotalIncome(response.data.data.total_income)
            setTransactionList(response.data.data.list)
        } catch (error) {
            console.log(error)
        } finally {
            setTransactionLoader(false)
        }
    }

    useEffect(() => {
        getReport()
    }, [periode])

    return (
        <>
            <div className="report">
                <div className="report-card mb-2">
                    <ul>
                        <li>
                            <input type="radio" name="periode" onChange={(e) => setPeriode(e.target.value)} value="Bulan ini" id="1" checked={periode == 'Bulan ini'}/>
                            <label htmlFor="1">Bulan ini</label>
                        </li>
                        <li>
                            <input type="radio" name="periode" onChange={(e) => setPeriode(e.target.value)} value="Bulan lalu" id="2" checked={periode == 'Bulan lalu'}/>
                            <label htmlFor="2">Bulan Lalu</label>
                        </li>
                        <li>
                            <input type="radio" name="periode" onChange={(e) => setPeriode(e.target.value)} value="3 Bulan" id="3" checked={periode == '3 Bulan'}/>
                            <label htmlFor="3">3 Bulan</label>
                        </li>
                    </ul>

                    <div className="report-dashboard">
                        <div>
                            <label>Selish</label>
                            {transactionLoader ? (
                                <p className="animated-background" style={{ height: '20px'}}></p>
                            ) : (
                                <p className="text-orange">Rp{new Intl.NumberFormat('ID-id').format(totalIncome - totalExpense)}</p>
                            )}
                        </div>
                        <div>
                            <label><FontAwesomeIcon icon={faArrowDown} className="text-success" /> Pemasukan</label>
                            {transactionLoader ? (
                                <p className="animated-background" style={{ height: '20px'}}></p>
                            ) : (
                                <p className="text-success">Rp{new Intl.NumberFormat('ID-id').format(totalIncome)}</p>
                            )}
                        </div>
                        <div>
                            <label><FontAwesomeIcon icon={faArrowUp} className="text-danger" /> Pengeluaran</label>
                            {transactionLoader ? (
                                <p className="animated-background" style={{ height: '20px'}}></p>
                            ) : (
                                <p className="text-danger">-Rp{new Intl.NumberFormat('ID-id').format(totalExpense)}</p>
                            )}
                        </div>
                    </div>
                </div>

                {transactionLoader ? (
            <div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
            </div>
        ) : (
            <>
                {Object.entries(transactionList).length > 0 && Object.entries(transactionList).map(([date, items]) => (
                    <div className="transaction-list" key={date}>
                        <div className="transaction-list-header">
                            <h3>{moment(date).format('dddd, DD MMM YYYY')}</h3> 
                            {/* <h3>Rp{new Intl.NumberFormat('ID-id').format(items.reduce((sum, item) => sum + item.nominal, 0))}</h3>  */}
                        </div>
                        <ul>
                            {items.map(item => (
                                <li key={item.id}>
                                    <div>
                                        <label>{item.category_name}</label>
                                        <p>{item.notes}</p>
                                    </div>
                                    <div>
                                        <label className={`${item.type === 'Income' ? 'text-success': 'text-danger'}`}>{item.type === 'Expense' && '-'}Rp{new Intl.NumberFormat('ID-in').format(item.nominal)}</label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </>
        )}
            </div>
        </>
    )
}

export default ReportTransaction