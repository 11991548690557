import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner, faArrowLeftLong, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import Api from "../../../services/api"
import moment from "moment"
import { useAuthContext } from "../../../context/AuthContext"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"

const Register = () => {
    const { showToast } = useAuthContext()
    const navigateTo = useNavigate()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: ''
    })
    const [formErrors, setFormErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const submitProcess = async (e) => {
        e.preventDefault()
        if(validasiForm()){

            try {
                setLoading(true)
                const payloads = {
                    name: formData.name,
                    email: formData.email,
                    password: formData.password
                }
                const response = await Api.post(`signup`, payloads)
                const exp = moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                // console.log(exp)

                if(response.data.status !== true){
                    showToast(response.data.message, 'error')
                    // toast.error(response.data.message, {
                    //     position: 'top-center'
                    // })
                } else {
                    // alert('Register berhasil')
                    showToast(response.data.message, 'success')
                    navigateTo('/register-verification', { state: { 
                        exp: exp,
                        email: formData.email,
                        name: formData.name
                    }})
                }

            } catch (error) {
                showToast('Terjadi kesalahan pada server', 'error')
                // toast.error('Terjadi kesalahan ada server', {
                //     position: 'top-center'
                // })
                console.log(error)
            } finally {
                setLoading(false)
            }
        } 
    }

    const validasiForm = () => {
        let valid = true
        const errors = {}

        if(!formData.name.trim()){
            errors.name = 'Nama tidak boleh kosong'
            valid = false
        }

        if(!formData.email.trim()){
            errors.email = 'Email tidak boleh kosong'
            valid = false
        }

        if(!formData.password.trim()){
            errors.password = 'Password tidak boleh kosong'
            valid = false
        }

        setFormErrors(errors)
        return valid
    }

    return (
        <div className="login-page">
            {/* <ToastContainer theme="colored" hideProgressBar={false} closeOnClick  /> */}
            <div className="header-page">
                <Link to="/"><FontAwesomeIcon icon={faArrowLeftLong} className="icon" /></Link>
                <div className="w-100 text-center"><h2>Daftar Sekarang</h2></div>
            </div>
            <div className="login-form">
                <p>Silahkan daftar untuk memulai aplikasi kamu</p>
                <form onSubmit={submitProcess}>
                    <div className="form-group">
                        <label>Nama</label>
                        <input type="text" className={`form-control ${formErrors.name ? 'is-invalid': ''}`} name="name" onChange={handleChange} value={formData.name} />
                        {formErrors.name && (<div className="invalid-feedback">{formErrors.name}</div>) }
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className={`form-control ${formErrors.email ? 'is-invalid': ''}`} name="email" onChange={handleChange} value={formData.email} />
                        {formErrors.email && (<div className="invalid-feedback">{formErrors.email}</div>) }
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <div className="password-wrapper">
                            <input type={`${showPassword ? 'text' : 'password'}`} className={`form-control shadow-none ${formErrors.password ? 'is-invalid': ''}`} name='password' value={formData.password} onChange={handleChange} />
                            <i onClick={() => setShowPassword(!showPassword)}><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></i>
                            {formErrors.password && (<div className="invalid-feedback">{formErrors.password}</div>) }
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block py-2" disabled={loading}>{loading ? (<><FontAwesomeIcon icon={faSpinner} spin /> Loading...</>) : 'Daftar' }</button>
                </form>
                {/* <div className="text-center mb-3">
                Sudah punya akun ? Login <Link to="/">di sini</Link>
                </div> */}
            </div>
        </div>
    )
}

export default Register