import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { useState } from "react"
import ExpenseAddForm from "../components/ExpenseAddForm"
import IncomeAddForm from "../components/IncomeAddForm"

const AddTransaction = () => {
    const [currenComponent, setCurrentComponent] = useState('expense')
    return (
        <div className="transaction-page section-page">
            <div className="transaction-page-header">
                <div className="section-page-header">
                    <Link to="/money-manager"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    <div className="text-center w-100"><h2>Tambah Catatan</h2></div>
                </div>
                <div className="section-page-hero">
                    <ul>
                        <li className={`${currenComponent == 'expense' ? 'active':''}`} onClick={() => setCurrentComponent('expense')}>Pengeluaran</li>
                        <li className={`${currenComponent == 'income' ? 'active':''}`} onClick={() => setCurrentComponent('income')}>Pemasukan</li>
                    </ul>
                </div>
            </div>
            <div className="section-page-body">
                {currenComponent == 'expense' ? (
                    <ExpenseAddForm />
                ) : (
                    <IncomeAddForm />
                )}
            </div>
            
        </div>
    )
}

export default AddTransaction