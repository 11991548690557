import { Link } from "react-router-dom"
import Logo from "../assets/images/logo-dzulfikar-nurfikri.png"
import hero from "../assets/images/6134223.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEyeSlash, faEye, faArrowDown, faArrowUp, faArrowRight, faCircle, faUser, faPlus } from "@fortawesome/free-solid-svg-icons"
import MobileNavigationFooter from "../components/MobileNavigationFooter"
import { useEffect, useState } from "react"
import Api from "../services/api"
import moment from "moment"
import 'moment/locale/id'

moment.locale('id')

const Home = () => {
    const [name, setName] = useState(localStorage.getItem('user'))
    const [saldo, setSaldo] = useState(0)
    const [totalIncome, setTotalIncome] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)
    const [transactionList, setTransactionList] = useState([])
    const [transactionLoader, setTransactionLoader] = useState(false)
    const [hiddenNominal, setHiddenNominal] = useState(false)

    const startOfMonth = moment().startOf('month').format('DD MMM YYYY')
    const endOfMonth = moment().endOf('month').format('DD MMM YYYY')

    const getTransactionList = async () => {
        try {
            setTransactionLoader(true)
            const payloads = {
                periode: 'Bulan ini'
            }
            const response = await Api.post(`transactions/byPeriode`, payloads, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })

            console.log(response.data)
            setTotalExpense(response.data.data.total_expense)
            setTotalIncome(response.data.data.total_income)
            setTransactionList(response.data.data.list)
        } catch (error) {
            console.log(error)
        } finally {
            setTransactionLoader(false)
        }
    }

    const getSaldo = async () => {
        try {
            setTransactionLoader(true)
            const payloads = {
                periode: 'Bulan ini'
            }
            const response = await Api.post(`transactions/saldo`, payloads, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })

            console.log(response.data)
            setSaldo(response.data.data)
        } catch (error) {
            console.log(error)
        } finally {
            setTransactionLoader(false)
        }
    }

    useEffect(() => {
        setName(localStorage.getItem('user'))
        getTransactionList()
        getSaldo()
    }, [])
    return (
        <div className="home-page">
            <div className="header">
                <div className="logo">
                    <FontAwesomeIcon icon={faUser} size="lg" className="" style={{ color: '#fff'}}/>
                </div>
                <h1>{name}</h1>
            </div>
            <div className="hero">
                <img src={hero} />
                <h2>Saldo</h2>
                {transactionLoader ? (
                    <p className="animated-background" style={{ height: '20px', width: '50%'}}></p>
                ) : (
                    <>
                        {hiddenNominal ? (
                            <>
                                <p style={{ fontSize: '8px'}} className="mt-1">
                                    <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                    <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                    <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                    <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                    <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                    <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                </p>
                            </>
                        ) : (
                            <>
                                <p>Rp {new Intl.NumberFormat('ID-id').format(saldo)}</p>
                            </>
                        )}
                    </>
                )}
            </div>

            <div className="section mb-2">
                <div className="section-header">
                    <div>
                        <h2>Catatan Keuangan<span className="text-orange">ku</span></h2>
                        <p>{startOfMonth} - {endOfMonth}</p>
                    </div>
                    <div>
                        <a href="#" className="font-weight-bold" onClick={() => setHiddenNominal(!hiddenNominal)}>
                            {hiddenNominal ? (<>Tampilkan <i><FontAwesomeIcon icon={faEye} /></i></>) : (<>Sembunyikan <i><FontAwesomeIcon icon={faEyeSlash} /></i></>)}
                        </a>
                    </div>
                </div>
                <div className="section-body">
                    <div>
                        <label>Pemasukan <FontAwesomeIcon icon={faArrowDown} /></label>
                        {transactionLoader ? (
                            <p className="animated-background" style={{ height: '20px'}}></p>
                        ) : (
                            <>
                                {hiddenNominal ? (
                                    <>
                                        <p style={{ fontSize: '8px'}} className="mt-1">
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p>Rp{new Intl.NumberFormat('ID-id').format(totalIncome)}</p>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div>
                        <label>Pengeluaran <FontAwesomeIcon icon={faArrowUp} /></label>
                        {transactionLoader ? (
                            <p className="animated-background" style={{ height: '20px'}}></p>
                        ) : (
                            <>
                                {hiddenNominal ? (
                                    <>
                                        <p style={{ fontSize: '8px'}} className="mt-1">
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p>Rp{new Intl.NumberFormat('ID-id').format(totalExpense)}</p>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="section-footer">
                    <div>
                        <label>Selisih</label>
                        {transactionLoader ? (
                            <p className="animated-background" style={{ height: '20px', width: '100%'}}></p>
                        ) : (
                            <>
                                {hiddenNominal ? (
                                    <>
                                        <p style={{ fontSize: '8px'}} className="mt-1">
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                            <FontAwesomeIcon icon={faCircle} className="mr-1" />
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p className="text-orange">Rp{new Intl.NumberFormat().format(totalIncome - totalExpense)}</p>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div>
                        <Link to="/money-manager"  className="font-weight-bold">Lihat Detail <i><FontAwesomeIcon icon={faArrowRight} /></i></Link>
                    </div>
                </div>
            </div>

            {transactionLoader ? (
                <div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                    <div className="mb-2 bg-white p-2">
                        <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                        <div className="d-flex justify-content-between">
                            <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                            <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {Object.entries(transactionList).length > 0 && Object.entries(transactionList).map(([date, items]) => (
                        <div className="transaction-list" key={date}>
                            <div className="transaction-list-header">
                                <h3>{moment(date).format('dddd, DD MMM YYYY')}</h3> 
                            </div>
                            <ul>
                                {items.map(item => (
                                    <li key={item.id}>
                                        <div>
                                            <label>{item.category_name}</label>
                                            <p>{item.notes}</p>
                                        </div>
                                        <div>
                                            <label className={`${item.type === 'Income' ? 'text-success': 'text-danger'}`}>{item.type === 'Expense' && '-'}Rp{new Intl.NumberFormat('ID-in').format(item.nominal)}</label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </>
            )}
            <MobileNavigationFooter />
        </div>
    )
}

export default Home