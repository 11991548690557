import { Link, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faList, faPlus, faUser, faSearch } from "@fortawesome/free-solid-svg-icons"

const MobileNavigationFooter = () => {
    const location = useLocation()
    console.log(location.pathname)
    return (
        <div className="footer">
            <ul>
                <li className={`${location.pathname === '/home' ? 'active':''}`}><Link to="/home"><i className="icon"><FontAwesomeIcon icon={faHome} /></i> Home</Link></li>
                <li><Link to="/money-manager"><i className="icon"><FontAwesomeIcon icon={faList} /></i> Transaksi</Link></li>
                <li><Link to="/add-transaction" className="plus"><i className="icon"><FontAwesomeIcon icon={faPlus} /></i></Link></li>
                <li><Link to="/search-transaction"><i className="icon"><FontAwesomeIcon icon={faSearch} /></i> Cari</Link></li>
                <li className={`${location.pathname === '/profile' ? 'active':''}`}><Link to="/profile"><i className="icon"><FontAwesomeIcon icon={faUser} /></i> Akun</Link></li>
            </ul>
        </div>
    )
}

export default MobileNavigationFooter