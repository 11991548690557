import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../services/api"
import moment from "moment"
import 'moment/locale/id'
import Empty from "../assets/images/paper.png"
import { useAuthContext } from "../context/AuthContext"

moment.locale('id')

const ExpenseTransactionList = () => {
    const { token } = useAuthContext()
    const [expenseList, setExpenseList] = useState([])
    const [transactionLoader, setTransactionLoader] = useState(false)

    const getExpenseList = async () => {
        try {
            setTransactionLoader(true)
            const response = await Api.get(`transactions/expense`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(response.data.data)
            setExpenseList(response.data.data)
        } catch (error) {
            console.log(error)
        } finally {
            setTransactionLoader(false)
        }
    }
    
    useEffect(() => {
        getExpenseList()        
    }, [])
    return (
        <>
        {transactionLoader ? (
            <div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
                <div className="mb-2 bg-white p-2">
                    <div className="animated-background mb-2" style={{ height: '25px'}}></div>
                    <div className="d-flex justify-content-between">
                        <div className="animated-background mb-2" style={{ height: '25px', width: '45%'}}></div>
                        <div className="animated-background mb-2" style={{ height: '25x', width: '45%'}}></div>
                    </div>
                </div>
            </div>
        ) : (
            <>
            {Object.entries(expenseList).length > 0 ? (
                <>
                    {Object.entries(expenseList).map(([date, items]) => (
                        <div className="transaction-list" key={date}>
                            <div className="transaction-list-header">
                                <h3>{moment(date).format('dddd, DD MMM YYYY')}</h3> 
                                <h3>Rp{new Intl.NumberFormat('ID-id').format(items.reduce((sum, item) => sum + item.nominal, 0))}</h3> 
                            </div>
                            <ul>
                                {items.map(item => (
                                    <li key={item.id}>
                                        <div>
                                            <label>{item.category_name}</label>
                                            <p>{item.notes}</p>
                                        </div>
                                        <div>
                                            <label>Rp{new Intl.NumberFormat('ID-in').format(item.nominal)}</label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    <div className="empty-box">
                        <img src={Empty} className="empty-image" />
                        <h4>Tidak ada data</h4>
                        <p>Untuk saat ini belum ada data transaksi</p>
                    </div>
                </>
            ) }
                
            </>
        )}

        <div className="transaction-footer">
            <div className="wrapper">
            <Link to="/add-transaction" className="btn btn-primary btn-block">Buat Catatan Baru</Link>
            </div>
        </div>
        </>
    )
}

export default ExpenseTransactionList