import { Outlet, Navigate } from "react-router-dom"

const AuthMiddleware = ({ role, redirectTo }) => {
    const token = localStorage.getItem('token')

    if(role == 'member'){
        if(token){
            return <Outlet />
        } else {
            <Navigate to={redirectTo} />
        }
        // return token ? <Outlet /> : <Navigate to={redirectTo} />
    }
    else if(role == 'guest'){
        return token ? <Navigate to={redirectTo} /> : <Outlet />
    }
}

export default AuthMiddleware