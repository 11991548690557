import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MobileNavigationFooter from "../components/MobileNavigationFooter"
import { faArrowLeft, faChevronRight, faSpinner, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Api from "../services/api"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import { useAuthContext } from "../context/AuthContext"

const Profile = () => {
    const { logoutAction, token, updateProfile, showToast } = useAuthContext()
    const [isOpen, setIsOpen] = useState(false)
    const [compo, setCompo] = useState('')
    const [profile, setProfile] = useState({})
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        is_active: '',
        password1: '',
        password2: ''
    })
    const [profileLoader, setProfileLoader] = useState(true)
    const [submitLoader, setSubmitLoader] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const navigateTo = useNavigate()
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)

    const openDetail = (comp) => {
        setCompo(comp)
        setIsOpen(true)
    }
    const closeDetail = () => {
        setCompo('')
        setIsOpen(false)
    }

    const logout = () => {
        showToast('Logout berhasil', 'success')
        logoutAction()
        navigateTo('/signin')
    }

    const getProfile = async () => {
        try {
            const response = await Api.get(`profile`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(response)

            if(response.data.status !== true){
                showToast(response.data.message, 'error')
                logoutAction()
                navigateTo('/')
                // toast.error(response.data.message, {
                //     position: 'top-center'
                // })
            } else {
                setProfile(response.data.data)
                setFormData({
                    name: response.data.data.name,
                    email: response.data.data.email,
                    is_active: response.data.data.is_active,
                    password1: '',
                    password2: ''
                })
            }

            
        } catch (error) {
            showToast('Terjadi kesalahan pada server', 'error')
            logoutAction()
            navigateTo('/')
            // toast.error('Terjadi kesalahan pada server', {
            //     position: 'top-center'
            // })
            console.log(error)
        } finally {
            setProfileLoader(false)
        }
    }

    const changeProfile = async (e) => {
        e.preventDefault()
        try {
            setSubmitLoader(true)
            const payloads = {
                name: formData.name,
                email: formData.email,
                is_active: formData.is_active
            }
            const response = await Api.post(`profile/update`, payloads, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(response)

            if(response.data.status !== true){
                showToast(response.data.message, 'error')
                // toast.error(response.data.message, {
                //     position: 'top-center'
                // })
            } else {
                updateProfile(formData)
                getProfile()
                setIsOpen(false)
                showToast(response.data.message, 'success')
                // toast.success(response.data.message, {
                //     position: 'top-center'
                // })
            }
            
        } catch (error) {
            showToast('Terjadi kesalahan pada server', 'error')
            // toast.error('Terjadi kesalahan pada server', {
            //     position: 'top-center'
            // })
            console.log(error)
        } finally {
            setSubmitLoader(false)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const changePassword = async (e) => {
        e.preventDefault()
        let valid = true
        const errors = {}

        if(!formData.password1.trim()){
            errors.password1 = 'Password Baru tidak boleh kosong'
            valid = false
        }

        if(!formData.password2.trim()){
            errors.password2 = 'Konfirmasi Password Baru tidak boleh kosong'
            valid = false
        }

        if(formData.password1 && formData.password2){
            if(formData.password2 !== formData.password1){
                errors.password2 = 'Konfirmasi Password Baru tidak sesuai'
                valid = false
            }
        }

        setFormErrors(errors)

        if(valid){
            try {
                const payloads = {
                    password: formData.password1
                }
                const response = await Api.post(`profile/updatePassword`, payloads, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                console.log(response)

                if(response.data.status !== true){
                    showToast(response.data.message, 'error')
                    // toast.error(response.data.message, {
                    //     position: 'top-center'
                    // })
                } else {
                    getProfile()
                    setIsOpen(false)
                    showToast(response.data.message, 'success')
                    // toast.success(response.data.message, {
                    //     position: 'top-center'
                    // })
                }

                
            } catch (error) {
                showToast('Terjadi kesalahan pada server', 'success')
                // toast.error('Terjadi kesalahan pada server', {
                //     position: 'top-center'
                // })
                console.log(error)
            }
        }
        
    }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <div className="profile-page">
            {/* <ToastContainer theme="colored" hideProgressBar={false} closeOnClick  /> */}
            <div className="profile-header">
                <div style={{width: '100%'}}>
                    {profileLoader ? (
                        <>
                            <div className="animated-background mb-1" style={{ height: '20px', width: '70%'}}></div>
                            <div className="animated-background" style={{ height: '20px', width: '50%'}}></div>
                        </>
                    ) : (
                        <>
                            <h1>{profile.name}</h1>
                            <p>{profile.email}</p>
                        </>
                    )}
                </div>
                <div>
                    <div className="avatar">{profileLoader === false && profile.name.slice(0,1)}</div>
                </div>
            </div>

            <div className="profile-body">
                <ul>
                    <li onClick={() => openDetail('Nama')}>
                        <label className="w-100">Nama</label>
                        <div className="w-100 text-right">
                            {profileLoader ? (
                                <span className="animated-background d-inline-block" style={{ height: '20px', width: '50%'}}></span>
                            ) : (
                                <>
                                    <span>{profile.name}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                </>
                            )}
                        </div>
                    </li>
                    <li onClick={() => openDetail('Email')}>
                        <label className="w-100">Email</label>
                        <div className="w-100 text-right">
                            {profileLoader ? (
                                <span className="animated-background d-inline-block" style={{ height: '20px', width: '50%'}}></span>
                            ) : (
                                <>
                                    <span>{profile.email}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                </>
                            )}
                        </div>
                    </li>
                    <li onClick={() => openDetail('Ubah Password')}>
                        <label className="w-100">Ubah Password</label>
                        <div className="w-100 text-right">
                            {profileLoader ? (
                                <span className="animated-background d-inline-block" style={{ height: '20px', width: '50%'}}></span>
                            ) : (
                                <>
                                    <span>*****</span>
                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                </>
                            )}
                        </div>
                    </li>
                    <li onClick={() => openDetail('Status Akun')}>
                        <label className="w-100">Status Akun</label>
                        <div className="w-100 text-right">
                            {profileLoader ? (
                                <span className="animated-background d-inline-block" style={{ height: '20px', width: '50%'}}></span>
                            ) : (
                                <>
                                    <span>{profile.is_active}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                </>
                            )}
                        </div>
                    </li>
                </ul>
            </div>

            <div className="profile-body">
                <button type="button" className="btn-logout border-0 bg-white" onClick={logout}>Keluar</button>
            </div>

            <div className={`detail-wrapper ${isOpen ? 'open':''}`}>
                <div className="detail-header">
                    <button href="#" onClick={() => closeDetail()} className="bg-transparent border-0"><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <div>
                        <h2>{compo}</h2>
                    </div>
                </div>
                <div className="detail-body">
                    {compo === 'Nama' ? (
                        <>
                        <form onSubmit={changeProfile}>
                            <div className="form-group">
                                <label>Nama Baru</label>
                                <input type="text" className="form-control" value={formData.name} name="name" onChange={handleChange}/>
                            </div>
                            <div className="detail-footer">
                                <button type="submit" className="btn btn-primary btn-block" disabled={submitLoader}>{submitLoader ? (<><FontAwesomeIcon icon={faSpinner} spin /> Loading...</>) : 'Simpan' }</button>
                            </div>
                        </form>
                        </>
                    ) : compo === 'Email' ? (
                        <>
                            <div className="alert alert-info">
                                Email kamu sudah terverifikasi
                            </div>
                        </>
                    ) : compo === 'Ubah Password' ? (
                        <>
                        <form onSubmit={changePassword}>
                            <div className="form-group">
                                <label>Password Baru</label>
                                <div className="password-wrapper">
                                    <input type={`${showPassword1 ? 'text' : 'password'}`} className={`form-control ${formErrors.password1 ? 'is-invalid': ''}`} name="password1" onChange={handleChange} value={formData.password1}/>
                                    <i onClick={() => setShowPassword1(!showPassword1)}><FontAwesomeIcon icon={showPassword1 ? faEyeSlash : faEye} /></i>
                                    {formErrors.password1 && (<div className="invalid-feedback">{formErrors.password1}</div>) }
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Konfirmasi Password Baru</label>
                                <div className="password-wrapper">
                                    <input type={`${showPassword2 ? 'text' : 'password'}`} className={`form-control ${formErrors.password2 ? 'is-invalid': ''}`} name="password2" onChange={handleChange} value={formData.password2}/>
                                    <i onClick={() => setShowPassword2(!showPassword2)}><FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} /></i>
                                    {formErrors.password2 && (<div className="invalid-feedback">{formErrors.password2}</div>) }
                                </div>
                            </div>

                            <div className="detail-footer">
                                <button type="submit" className="btn btn-primary btn-block" disabled={submitLoader}>{submitLoader ? (<><FontAwesomeIcon icon={faSpinner} spin /> Loading...</>) : 'Ubah' }</button>
                            </div>
                        </form>
                        </>
                    ) : compo === 'Status Akun' && (
                        <>
                            <div className="alert alert-success">
                                Status akun kamu saat ini <strong>Aktif</strong>
                            </div>
                        </>
                    ) }
                    
                </div>
            </div>

            <MobileNavigationFooter />
        </div>
    )
}

export default Profile