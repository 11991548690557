import { Routes, Route } from "react-router-dom";
import MainTemplate from "../layouts/MainTemplate";
import Login from "../views/Authentication/Login";
import Register from "../views/Authentication/Registration";
import Home from "../views/Home";
import MoneyManager from "../views/MoneyManager";
import AddTransaction from "../views/AddTransaction";
import Profile from "../views/Profile";
import SearchTransaction from "../views/SearchTransaction";
import AuthMiddleware from "../middleware/AuthMiddleware";
import BlankTemplate from "../layouts/BlankTemplate";
import ForgotPassword from "../views/Authentication/ForgotPassword";
import RegisterVerifyOTP from "../views/Authentication/Registration/VerifyOTP";
import ForgotPasswordVerifyOTP from "../views/Authentication/ForgotPassword/VerifyOTP";
import ResetPassword from "../views/Authentication/ForgotPassword/Reset";
import Welcome from "../views/Welcome";

const AppRouter = () => {
    return (
        <Routes>
            <Route element={<BlankTemplate />}>
                <Route element={<AuthMiddleware role="guest" redirectTo="/home" />}>
                    <Route path="/" element={<Welcome />}></Route>
                    <Route path="/signin" element={<Login />}></Route>
                    <Route path="/signup" element={<Register />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                    <Route path="/forgot-password/verify" element={<ForgotPasswordVerifyOTP />}></Route>
                    <Route path="/forgot-password/reset" element={<ResetPassword />}></Route>
                    <Route path="/register-verification" element={<RegisterVerifyOTP />}></Route>
                </Route>
            </Route>
            <Route element={<MainTemplate />}>
                <Route element={<AuthMiddleware role="member" redirectTo="/signin" />}>
                    <Route path="/home" element={<Home />}></Route>
                    <Route path="/money-manager" element={<MoneyManager />}></Route>
                    <Route path="/add-transaction" element={<AddTransaction />}></Route>
                    <Route path="/profile" element={<Profile />}></Route>
                    <Route path="/search-transaction" element={<SearchTransaction />}></Route>
                </Route>
            </Route>
        </Routes>
    )
}

export default AppRouter