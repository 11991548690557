import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons"
import Api from "../../../services/api"
import moment from "moment"
import { useAuthContext } from "../../../context/AuthContext"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"

const ForgotPassword = () => {
    const { showToast } = useAuthContext()
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const [formErrors, setFormErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const navigateTo = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    const forgotPasswordProcess = async (e) => {
        e.preventDefault()
        // setErrorStatus(false)

        if(validasiForm()){

            try {
                setLoading(true)
                const payloads = {
                    email: formData.email
                }
                const exp = moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                const response = await Api.post(`forgotPassword`, payloads)

                console.log(response)

                if(response.data.status !== true){
                    // toast.error(response.data.message, {
                    //     position: 'top-center'
                    // })
                    showToast(response.data.message, 'error')
                } else {
                    navigateTo('/forgot-password/verify', { state: { 
                        exp: exp,
                        email: formData.email,
                        name: response.data.data
                    }})
                }

            } catch (error) {
                // toast.error('Terjadi kesalahan pada server', {
                //     position: 'top-center'
                // })
                showToast('Terjadi kesalahan pada server', 'error')
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
    }

    const validasiForm = () => {
        let valid = true
        const errors = {}

        if(!formData.email.trim()){
            errors.email = 'Email tidak boleh kosong'
            valid = false
        }

        setFormErrors(errors)
        return valid
    }
    return (
        <div className="login-page">
            {/* <ToastContainer theme="colored" hideProgressBar={false} closeOnClick  /> */}
            <div className="header-page">
                <Link to="/signin"><FontAwesomeIcon icon={faArrowLeftLong} className="icon" /></Link>
                <div className="w-100 text-center"><h2>Lupa Password</h2></div>
            </div>
            <div className="login-form">
                <p>Masukkan email kamu</p>
                <form onSubmit={forgotPasswordProcess}>
                    <div className="form-group">
                        <label>Email </label>
                        <input type="text" className={`form-control ${formErrors.email ? 'is-invalid': ''}`} name='email' value={formData.email} onChange={handleChange}/>
                        {formErrors.email && (<div className="invalid-feedback">{formErrors.email}</div>) }
                    </div>
                    <button type="submit" className="btn btn-primary btn-block py-2" disabled={loading}>{loading ? (<><FontAwesomeIcon icon={faSpinner} spin /> Loading...</>) : 'Kirim' }</button>
                </form>
                {/* <div className="text-center mb-3">
                <Link to="/">Kembali ke Login</Link>
                </div> */}
            </div>
        </div>
    )
}

export default ForgotPassword