import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWallet } from "@fortawesome/free-solid-svg-icons"

const Welcome = () => {
    return (
        <div className="welcome">
            <div className="hero">
                <div className="image">
                    <FontAwesomeIcon icon={faWallet} className="icon" />
                </div>
                <h1>DompetKu</h1>
                <p>Mulai kelola keuanganmu dengan aplikasi DompetKu sekarang</p>
            </div>
            <div className="content">

                <div className="">
                    <Link to="/signup" className="btn btn-primary btn-block py-2 mb-3">Daftar Sekarang</Link>
                    <Link to="/signin" className="btn btn-outline-primary btn-block py-2">Masuk</Link>
                </div>
                
            </div>
        </div>
    )
}

export default Welcome